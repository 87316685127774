import React from 'react'
import Header from '../../views/Header/Header';
import Container from '../../common/Container/Container';
import OurServices from '../../views/OurServices/OurServices';
import AboutUs from '../../views/AboutUs/AboutUs';
import Blog from '../../views/Blog/Blog';
import ContactUs from '../../views/ContactUs/ContactUs';
import OurReviews from '../../views/OurReviews/OurReviews';

const Home = ({ ourServicesRef,
  aboutUsRef,
  blogRef,
  ourReviewsRef,
  contactUsRef
}) => {

  return (
    <>
      <Header
        ourServicesRef={ourServicesRef}
        aboutUsRef={aboutUsRef}
        blogRef={blogRef}
        ourReviewsRef={ourReviewsRef}
        contactUsRef={contactUsRef}
      />
      <Container containerPadding={true}>
        <OurServices ourServicesRef={ourServicesRef} />
      </Container>
      <Container bgWhite={true}>
        <AboutUs aboutUsRef={aboutUsRef} />
      </Container>
      <Container>
        <Blog blogRef={blogRef} />
      </Container>
      <Container bgWhite={true}>
        <OurReviews ourReviewsRef={ourReviewsRef} />
      </Container>
      <ContactUs contactUsRef={contactUsRef} />
    </>
  )
}

export default Home