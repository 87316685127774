import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPostById } from '../../../redux/postsReducer';
import Header from '../../views/Header/Header';

const SinglePost = () => {

  const { id } = useParams();
  const productData = useSelector(state => getPostById(state, id));
  const startRef = useRef(null);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
    });
  };
  

  useEffect (()=>{
    handleScroll(startRef.current);
    console.log('component rendered');
  }, []);

  return (
    <>
    <Header />
    <div ref={startRef} style={{padding: '30px 10%'}}>
      <h1>It's page for post number {productData.id}</h1>
      <img style={{width: '300px'}} src={productData.image} alt={`imageForPost${productData.id}`}></img>
      <pre style={{fontFamily: 'Gilroy', whiteSpace: 'break-spaces'}}>{productData.content}</pre>
    </div>
    </>
  )
}

export default SinglePost