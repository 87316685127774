function truncateText(text, maxWords = 35) {
  const words = text.split(' ');

  if (words.length <= maxWords) {
    return text;
  } else {
    const truncatedWords = words.slice(0, maxWords);
    let truncatedText = truncatedWords.join(' ');

    // Удалить точку (.) или пробел ( ) в конце текста перед добавлением "..."
    truncatedText = truncatedText.replace(/[. ]+$/, '');

    if (words.length > maxWords) {
      truncatedText += '...';
    }

    // Проверить, если следующий абзац начинается после "..."
    const remainingText = words.slice(maxWords).join(' ');
    if (remainingText.trim().startsWith('\n')) {
      truncatedText += '\n';
    }

    return truncatedText;
  }
}

export default truncateText;