//selectors
export const getAllAdvantages = ({ advantages }) => advantages;

const advantagesReducer = (statePart = [], action) => {
  switch (action.type) {
    default:
      return statePart;
  };
};

export default advantagesReducer;