import React from 'react';
import styles from './NavBar.module.scss';
// import { BsChevronDown } from 'react-icons/bs';
// import { FiSearch } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { scrollToElement } from '../../../utils/scrollUtils';
import { useState } from 'react';
import clsx from 'clsx';
import { MdPhoneInTalk } from 'react-icons/md';
import { FaEnvelope, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import ContactForm from '../../features/ContactForm/ContactForm';

const NavBar = ({
  ourServicesRef,
  aboutUsRef,
  blogRef,
  ourReviewsRef,
  contactUsRef
}) => {

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  /* const handleNavLinkClick = (elementId, pixelsToScroll = 0) => {
    scrollToElement(elementId, pixelsToScroll);
    setIsNavExpanded(false); // Закрыть навигационное меню после клика на ссылку
  }; */

  const navigate = useNavigate();

  const handleScroll = (ref) => {
    setIsNavExpanded(false);
    if (!ref) {
      navigate(`/#${ref.id}`);
    } else {
      scrollToElement(ref);
    }
  };

  return (
    <div className={clsx(styles.navBar, isNavExpanded && styles.open)}>
      <div className={styles.logo}>
        <a href='/'>
          <img src='/img/logo.png' alt='logo' />
        </a>
      </div>
      <div className={styles.tabletsImageMenu}>
        {/* <img src='./img/tablets-image-menu.jpg' alt='menuImage' /> */}
        <ContactForm titleContent={'Обратный звонок'} />
      </div>
      <ul className={styles.mainList}>
        <li className={styles.dropdownParent}>
          <Link to={'/#ourServices'} onClick={() => handleScroll(ourServicesRef.current)}>
            <small>Услуги</small> {/* <BsChevronDown /> */}
          </Link>
          {/* <ul className={styles.dropdown}>
            <li><a href='/first1'>Международная защита</a></li>
            <li><a href='/two2'>Получение внж</a></li>
            <li><a href='/three3'>Получение номера pesel</a></li>
            <li><a href='/four4'>Регистрация авто</a></li>
          </ul> */}
        </li>
        <li>
          <Link to={'/#aboutUs'} onClick={() => handleScroll(aboutUsRef.current)}>
            <small>О компании</small>
          </Link>
        </li>
        <li>
          <Link to={'/#ourReviews'} onClick={() => handleScroll(ourReviewsRef.current)}>
            <small>Отзывы</small>
          </Link>
        </li>
        <li>
          <Link to={'/#blog'} onClick={() => handleScroll(blogRef.current)}>
            <small>Блог</small>
          </Link>
        </li>
        <li>
          <Link to={'/#contactUs'} onClick={() => handleScroll(contactUsRef.current)}>
            <small>Контакты</small>
          </Link>
        </li>
      </ul>
      <ul className={styles.secondaryList}>
        <li className={styles.phoneNumber}>
          <a href='tel:+48 500 173 323'><u>+48 500 173 323</u></a>
        </li>
        {/* <li>
          <a href='/'>
            <small>RU <BsChevronDown /></small>
          </a>
        </li> */}
        {/* <li>
          <button className={styles.tooltip}>
            <span className={styles.tooltipText}>Нажмите для поиска</span>
            <FiSearch />
          </button>
        </li> */}
        <li className={styles.hamburgerWrapper}>
          <div
            role='button'
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
            className={clsx(styles.hamburger, isNavExpanded && styles.open)}
          >
            <div className={styles.iconLeft}></div>
            <div className={styles.iconRight}></div>
          </div>
        </li>
      </ul>
      <div className={styles.massagers}>
        <span className={styles.massagersIcons}>
          Напишите нам:
          <span className={styles.telegram}><a href='https://t.me/Hil_Juris' target="_blank" rel="noreferrer"><FaTelegramPlane /></a></span>
          <span className={styles.viber}><a href='viber://chat?number=%2B48500173323' target="_blank" rel="noreferrer"><MdPhoneInTalk /></a></span>
          <span className={styles.whatsapp}><a href='https://wa.me/48500173323' target="_blank" rel="noreferrer"><FaWhatsapp /></a></span>
        </span>
        <span className={styles.massagersEmail}>
          <a href='mailto: hil.juris@gmail.com'><FaEnvelope />hil.juris@gmail.com</a>
          <p>Пишите в любое время дня и ночи</p>
        </span>
      </div>
    </div>
  )
}

export default NavBar