import React from 'react';
import styles from './Footer.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { scrollToElement } from '../../../utils/scrollUtils';
import { MdPhoneInTalk } from 'react-icons/md';
import { FaEnvelope, FaFacebookF, FaInstagram, FaTelegramPlane, FaVk, FaWhatsapp } from 'react-icons/fa';

const Footer = ({
  ourServicesRef,
  aboutUsRef,
  blogRef,
  ourReviewsRef,
  contactUsRef
}) => {
  const navigate = useNavigate();

  const handleScroll = (ref) => {
    if (!ref) {
      navigate(`/#${ref.id}`);
    } else {
      scrollToElement(ref);
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <a href='/'>
          <img src='/img/logo.png' alt='logo' />
        </a>
      </div>
      <div className={styles.menu}>
        <ul className={styles.menuFirstCol}>
          <li className={styles.dropdownParent}>
            <Link to={'/#ourServices'} onClick={() => handleScroll(ourServicesRef.current)}>
              <small>Услуги</small> {/* <BsChevronDown /> */}
            </Link>
            {/* <ul className={styles.dropdown}>
            <li><a href='/first1'>Международная защита</a></li>
            <li><a href='/two2'>Получение внж</a></li>
            <li><a href='/three3'>Получение номера pesel</a></li>
            <li><a href='/four4'>Регистрация авто</a></li>
          </ul> */}
          </li>
          <li>
            <Link to={'/#aboutUs'} onClick={() => handleScroll(aboutUsRef.current)}>
              <small>О компании</small>
            </Link>
          </li>
          <li>
            <Link to={'/#ourReviews'} onClick={() => handleScroll(blogRef.current)}>
              <small>Отзывы</small>
            </Link>
          </li>
        </ul>
        <ul className={styles.menuSecondCol}>
          <li>
            <Link to={'/#blog'} onClick={() => handleScroll(ourReviewsRef.current)}>
              <small>Блог</small>
            </Link>
          </li>
          <li>
            <Link to={'/#contactUs'} onClick={() => handleScroll(contactUsRef.current)}>
              <small>Контакты</small>
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.phone}>
        <a href='tel: +48 500 173 323'><u>+48 500 173 323</u></a>
      </div>
      <div className={styles.massagers}>
        <span className={styles.massagersIcons}>
          Напишите нам:
          <span className={styles.telegram}><a href='https://t.me/Hil_Juris' target="_blank" rel="noreferrer"><FaTelegramPlane /></a></span>
          <span className={styles.viber}><a href='viber://chat?number=%2B48500173323' target="_blank" rel="noreferrer"><MdPhoneInTalk /></a></span>
          <span className={styles.whatsapp}><a href='https://wa.me/48500173323' target="_blank" rel="noreferrer"><FaWhatsapp /></a></span>
        </span>
        <span className={styles.massagersEmail}>
          <a href='mailto: hil.juris@gmail.com'><FaEnvelope />hil.juris@gmail.com</a>
          <p>Пишите в любое время дня и ночи</p>
        </span>
      </div>
      <div className={styles.socialMedia}>
        <span>
          Warszawa, ul. Kubusia Puchatka 18/2
        </span>
        <span className={styles.socialMediaIcons}>
          <span className={styles.Instagram}><a href='https://www.instagram.com/hil_juris/' target="_blank" rel="noreferrer"><FaInstagram /></a></span>
          <span className={styles.facebook}><a href='https://www.instagram.com/hil_juris/' target="_blank" rel="noreferrer"><FaFacebookF /></a></span>
          <span className={styles.vk}><a href='https://www.instagram.com/hil_juris/' target="_blank" rel="noreferrer"><FaVk /></a></span>
        </span>
      </div>
      <div className={styles.copyRight}>
        2023 все права защищены
      </div>
    </footer>
  )
}

export default Footer