import React from 'react';
import styles from './Header.module.scss';
import NavBar from '../NavBar/NavBar';
import { BsArrowDown } from 'react-icons/bs';
import ContactForm from '../../features/ContactForm/ContactForm';
import { scrollToElement } from '../../../utils/scrollUtils';
import { Link } from 'react-router-dom';

const Header = ({
  ourServicesRef,
  aboutUsRef,
  blogRef,
  ourReviewsRef,
  contactUsRef
}) => {
  return (
    <header className={styles.header}>
      <NavBar
        ourServicesRef={ourServicesRef}
        aboutUsRef={aboutUsRef}
        blogRef={blogRef}
        ourReviewsRef={ourReviewsRef}
        contactUsRef={contactUsRef}
      />
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <h1><span>легализация</span> в польше</h1>
          <div className={styles.heroContentSmall}>
            <h3>Юридические услуги по вопросам иммиграции в Польше</h3>
          </div>
          <div className={styles.heroBtnWrapper}>
            <button onClick={() => scrollToElement('ourServicesSection')}>
              <BsArrowDown />
            </button>
            <Link onClick={() => scrollToElement('ourServicesSection')}><small>Подробнее</small></Link>
          </div>
        </div>
        <div className={styles.heroContactForm}>
          <ContactForm titleContent={'Бесплатная консультация'} />
        </div>
      </div>
    </header>
  )
}

export default Header