/* import { createStore, combineReducers } from 'redux';
import initialState from './initialState';
import reviewsReducer from './reviewsReducer';
import advantagesReducer from './advantagesReducer';
import postsReducer from './postsReducer';

const subreducers = {
  reviews: reviewsReducer,
  advantages: advantagesReducer,
  posts: postsReducer
}

const reducer = combineReducers(subreducers);
const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store; */

import { configureStore } from '@reduxjs/toolkit';
import initialState from './initialState';
import reviewsReducer from './reviewsReducer';
import advantagesReducer from './advantagesReducer';
import postsReducer from './postsReducer';
import servicesReducer from './servicesReducer';

const store = configureStore({
  reducer: {
    reviews: reviewsReducer,
    advantages: advantagesReducer,
    posts: postsReducer,
    services: servicesReducer
  },
  preloadedState: initialState
});

export default store;
