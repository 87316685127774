import React from 'react';
import styles from './Container.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Container = (props) => {

  const padding = props.padding;
  const whiteBg = props.bgWhite;
  const children = props.children;

  return (
    <div className={clsx(
        padding ? styles.container : styles.containerPadding, 
        whiteBg && styles.containerWhite
      )}>
        {children}
    </div>
  )
}

Container.propTypes = {
  padding: PropTypes.bool,
  whiteBg: PropTypes.bool,
  children: PropTypes.object.isRequired
}

export default Container