import React, { useState } from 'react';
import styles from './OurServices.module.scss';
import { BsArrowUpRight } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { getAllServices } from '../../../redux/servicesReducer';
import { scrollToElement } from '../../../utils/scrollUtils';

const OurServices = ({ourServicesRef}) => {
  const services = useSelector(getAllServices);

  const [visibleBlocks, setVisibleBlocks] = useState(5);


  const showAll = () => {
    setVisibleBlocks(services.length); // Показать все
  };

  const show4 = () => {
    setVisibleBlocks(5); // Показать 5
    scrollToElement('ourServicesSection');
  };

  return (
    <section id={'ourServicesSection'} ref={ourServicesRef} className={styles.ourServices}>
      <div className={styles.ourServicesWrapper}>
        <div className={styles.ourServicesWrapperBlock}>
          <h2>
          Предоставляемые<br></br>
          нами услуги
          </h2>
        </div>
        {services.slice(0, visibleBlocks).map (service =>(
          <div className={styles.ourServicesWrapperBlock} key={service.id}>
            <span className={styles.iconBlock}><i className={`fa-solid fa-${service.icon}`}></i></span>
            <h3>
              {service.title} <span className={styles.arrowUpRight}><BsArrowUpRight/></span>
            </h3>
            <small>
              {service.shortDescription}
            </small>
          </div>
        ))}
      </div>
      {visibleBlocks < services.length ? (
          <button className={styles.showMoreBtn} onClick={showAll}><u>Показать все услуги</u></button>
        )
        :
        (
          <button className={styles.showMoreBtn} onClick={show4}><u>Свернуть</u></button>
        )
      }
    </section>
  )
}

export default OurServices