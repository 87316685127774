import React from 'react';
import styles from './ContactUs.module.scss';
import ContactForm from '../../features/ContactForm/ContactForm';

const ContactUs = ({contactUsRef}) => {
  return (
    <section className={styles.contactUs} id={'contactUsSection'} ref={contactUsRef}>
      <div className={styles.content}>
        <h1>Давайте обсудим вашу задачу и найдем <span>лучшее решение</span></h1>
        <p>Напишите нам, позвоните или закажите обратный звонок</p>
        <div className={styles.ourContacts}>
          <p><a href='tel:+48 500 173 323'>+48 500 173 323</a></p>
          <a href='mailto: hil.juris@gmail.com'>hil.juris@gmail.com</a>
        </div>
      </div>
      <div className={styles.contactUsContactForm}>
        <ContactForm titleContent={'Мы поможем'} />
      </div>
    </section>
  )
}

export default ContactUs