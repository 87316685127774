import Footer from "./components/views/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home/Home";
import ScrollButton from "./components/common/ScrollButton/ScrollButton";
import NotFound from "./components/pages/NotFound/NotFound";
import SinglePost from "./components/pages/SinglePost/SinglePost";
import { useEffect, useRef } from "react";
import { scrollToElement } from "./utils/scrollUtils";

function App() {
  const ourServicesRef = useRef(null);
  const aboutUsRef = useRef(null);
  const blogRef = useRef(null);
  const ourReviewsRef = useRef(null);
  const contactUsRef = useRef(null);

  useEffect(()=>{
    const hash = window.location.hash;

    switch (hash) {
      case '#ourServices':
        scrollToElement(ourServicesRef.current, 'auto')
        break;
      case '#aboutUs':
        scrollToElement(aboutUsRef.current, 'auto')
        break;
      case '#blog':
        scrollToElement(blogRef.current, 'auto')
        break;
      case '#ourReviews':
        scrollToElement(ourReviewsRef.current, 'auto')
        break;
      case '#contactUs':
        scrollToElement(contactUsRef.current, 'auto')
        break;
    
      default:
        break;
    }
  },[]);

  return (
    <>
      <Routes exact path={["/#ourServices", "/#aboutUs", "/#blog", "/#ourReviews", "/#contactUs"]}>
        <Route path='/' element={<Home
          ourServicesRef={ourServicesRef}
          aboutUsRef={aboutUsRef}
          blogRef={blogRef}
          ourReviewsRef={ourReviewsRef}
          contactUsRef={contactUsRef}
        />} />
        <Route path='*' element={<NotFound />} />
        <Route path='/blog/:id' element={<SinglePost />} />
      </Routes>
      <ScrollButton />
      <Footer
        ourServicesRef={ourServicesRef}
        aboutUsRef={aboutUsRef}
        blogRef={blogRef}
        ourReviewsRef={ourReviewsRef}
        contactUsRef={contactUsRef}
      />
    </>
  );
}

export default App;
