//selectors
export const getAllServices = ({ services }) => services;

const servicesReducer = (statePart = [], action) => {
  switch (action.type) {
    default:
      return statePart;
  };
};

export default servicesReducer;