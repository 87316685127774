import React from 'react'
import styles from './OurReviews.module.scss';
import { useSelector } from 'react-redux';
import { getAllReviews } from '../../../redux/reviewsReducer';
import Slider from 'react-slick';
import { getInitials } from '../../../utils/fullNameToInitials';

const OurReviews = ({ourReviewsRef}) => {
  const reviews = useSelector(getAllReviews);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 10000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section id={'reviewsSection'} ref={ourReviewsRef} className={styles.ourReviews}>
      <h2>Отзывы о нас</h2>
      <Slider className={styles.carousel} {...settings}>
        {reviews.map (review => (
          <div className={styles.carouselItem} key={review.id}>
            <div className={styles.carouselItemContent}>
              <p>{review.content}</p>
            </div>
            <div className={styles.carouselItemProfile}>
              <span className={styles.photo}>
                {getInitials(review.author)}
              </span>
              <span className={styles.contactInfo}>
                <p>{review.author}</p>
                {/* <p>{review.country}</p> */}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  )
}

export default OurReviews