import React from 'react'
import styles from './NotFound.module.scss';
import { Link } from 'react-router-dom';
import { MdPhoneInTalk } from 'react-icons/md';
import { FaInstagram, FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <div className={styles.notFoundWrapper}>
        <div className={styles.notFound404}>
          <h1>404</h1>
        </div>
        <h2>Упс! НИЧЕГО НЕ НАЙДЕНО</h2>
        <p>Возможно, страница которую вы искали была удалена, изменила свое название или временно недоступна. <Link to='/'>Вернуться на главную страницу</Link></p>
        {/* <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable. <Link to='/'>Return to homepage</Link></p> */}
        <div className={styles.notFoundSocial}>
          <a href='https://t.me/Hil_Juris' target="_blank" rel="noreferrer"><FaTelegramPlane /></a>
          <a href='viber://chat?number=%2B48500173323' target="_blank" rel="noreferrer"><MdPhoneInTalk /></a>
          <a href='https://wa.me/48500173323' target="_blank" rel="noreferrer"><FaWhatsapp /></a>
          <a href='https://www.instagram.com/hil_juris/' target="_blank" rel="noreferrer"><FaInstagram /></a>
        </div>
      </div>
    </div>
  )
}

export default NotFound